import * as React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

import CalendarIcon from "../images/calendar.svg"
import Line from "../images/line.svg"
import moment from 'moment'
import "../styles/index.css"
import styled from "@emotion/styled"
import { css } from "@emotion/react"



const IndexPage = ({ data }) => {
  const Hero = styled.div`
    width: 100%;
    height: 450px;
    background-size: contain;
  `
  return (
    <Layout>
      <Seo title="Coding Samples - Developer tips to strengthen your skills" image={`${data.site.siteMetadata?.siteUrl}${data.file.publicURL}`} />
      <Line className="line" />
      <Hero className="hero">
        <div className="container text-center pt-5">
          <h1 className="hero-title">Helping you to become the <span style={{ color: '#ed5035' }}>wisest</span> developer of your team</h1>
          <h2 className="hero-subtitle">{data.homepage.Subtitle}</h2>
          <div className="d-flex justify-content-center flex-wrap text-start mt-3 mt-md-5">
            <div className="col-12 col-md-8 p-2">
              <div className="article-image">
                <div className="featured-img" style={{ backgroundImage: `url(${data.homepage.articleHeader.Cover.formats.large.url})` }}>
                  <div className="d-flex mt-4" style={{ marginLeft: '2rem' }}>
                    <div className="category">{data.homepage.articleHeader.category.Name}</div>
                  </div>

                  <h3 className="text-shadow-1">
                    <Link to={`/article/${data.homepage.articleHeader.Slug}/`} className="anchor-full">
                      <span className="span-title">{data.homepage.articleHeader.Title}</span>
                    </Link>
                  </h3>

                  <p className="text-shadow-1 mb-4"><span className="span-preview">{data.homepage.articleHeader.Preview}</span></p>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <Img fixed={data.homepage.articleHeader.author?.Picture?.localFile?.childImageSharp?.fixed} alt="author picture" className="rounded-circle border border-white" />
                    </li>
                    <li className="d-flex align-items-center">
                      <CalendarIcon className="me-2" />
                      <small>{moment(data.homepage.articleHeader.Creation).format('LL')}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hero>

      <div className="container px-4 py-5 mt-2 mt-md-0" id="custom-cards">
        <h3 className="pb-2 text-center border-bottom">Must read</h3>
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          {data.homepage.articlesFeatured.map(article => (
            <div className="col" key={article.id}>
              <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: `url(${article.Cover.formats.large.url})` }}>
                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h2 className="pt-5 mt-5 mb-4 lh-1 fw-bold">
                    <Link to={`/article/${article.Slug}/`} className="anchor-full">
                      {article.Title}
                    </Link>
                  </h2>
                  <p className="text-white text-shadow-1">{article.Preview}</p>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <Img fixed={article.author?.Picture?.localFile?.childImageSharp?.fixed} alt="author picture" className="rounded-circle border border-white" />
                    </li>
                    <li className="d-flex align-items-center">
                      <CalendarIcon className="me-2" />
                      <small>{moment(article.Creation).format('LL')}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container" id="custom-cards">
        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4">
          {data.allStrapiArticle.edges.map(article => (
            <div className="col" key={article.node.id}>
              <div className="card card-cover h-100 overflow-hidden text-white bg-dark rounded-5 shadow-lg" style={{ backgroundImage: `url(${article.node.Cover.formats.large.url})` }}>
                <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                  <h2 className="pt-5 mt-5 mb-4 lh-1 fw-bold">
                    <Link to={`/article/${article.node.Slug}/`} className="anchor-full">
                      {article.node.Title}
                    </Link>
                  </h2>
                  <ul className="d-flex list-unstyled mt-auto">
                    <li className="me-auto">
                      <Img fixed={article.node.author?.Picture?.localFile?.childImageSharp?.fixed} alt="author picture" className="rounded-circle border border-white" />
                    </li>
                    <li className="d-flex align-items-center">
                      <CalendarIcon className="me-2" />
                      <small>{moment(article.node.Creation).format('LL')}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Link to="/page/2" className="next-page px-2 py-2">Next tips</Link>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    file (base: { eq: "default-meta.jpg" }) {
      publicURL
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    allStrapiArticle(sort: { fields: Creation, order: DESC }) {
      edges {
        node {
          id
          Title
          Preview
          Creation
          Slug
          Cover {
            formats {
              medium {
                url
              }
              large {
                url
              }
            }
          }
          author {
            Picture {
              localFile {
                childImageSharp {
                  fixed(width: 32, height: 32) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
    homepage: strapiHomepage {
      Title
      Subtitle
      articleHeader {
        Title
        Preview
        Creation
        Slug
        Cover {
          formats {
            medium {
              url
            }
            large {
              url
            }
          }
        }
        category {
          Name
        }
        author {
          Picture {
            localFile {
              childImageSharp {
                fixed(width: 32, height: 32) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
      articlesFeatured {
        id
        Title
        Preview
        Slug
        Creation
        Body
        Cover {
          formats {
            medium {
              url
            }
            large {
              url
            }
          }
        }
        author {
          Picture {
            localFile {
              childImageSharp {
                fixed(width: 32, height: 32) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`